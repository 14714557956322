<template>
  <header>
    <div class="container mx-auto p-5 lg:p-0">
      <div class="flex justify-between">
        <router-link to="/Home"
          ><img src="@/assets/images/logo.png" alt="" class="logo"
        /></router-link>
        <router-link to="/contact" class="main-btn">
          <svg class="icon" data-v-df212a54="">
            <use xlink:href="#map-marker-white" data-v-df212a54=""></use></svg
          >Contact Us
        </router-link>
      </div>
    </div>
  </header>
  <div class="container mx-auto p-5 lg:p-0">
    <div class="hero-wrapper">
      <h1 class="title">I am a....</h1>
      <div class="lg:grid grid-cols-3 gap-4">
        <div class="">
          <router-link to="/ftl" class="widget bg-red mb-3 lg:mb-0">
            <svg class="icon">
              <use xlink:href="#ftl"></use>
            </svg>
            <div class="widget-title">FTL Carrier</div>
          </router-link>
        </div>
        <div class="">
          <div @click="loginLTL" class="widget bg-blue mb-3 lg:mb-0">
            <svg class="icon l-height">
              <use xlink:href="#ltl"></use>
            </svg>
            <div class="widget-title">LTL Carrier</div>
          </div>
        </div>
        <div class="">
          <div @click="loginFactoringCompany" class="widget bg-orange">
            <svg class="icon">
              <use xlink:href="#factoring-company"></use>
            </svg>
            <div class="widget-title">Factoring Company</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footerbar />
</template>
<script>
import Footerbar from "@/components/common/Footerbar.vue";
export default {
  components: {
    Footerbar,
  },
  methods: {
    loginLTL() {
      if (this.isAuthed == true) {
        this.$router.push("/ltl");
      } else {
        this.$router.push("/login?l=ltl");
      }
    },
    loginFactoringCompany() {
      if (this.isAuthed == true) {
        this.$router.push("/factoring-company");
      } else {
        this.$router.push("/login?l=factoringcompany");
      }
    },
  },
  computed: {
    isAuthed: function () {
      return this.$store.state.login.authed == true ? true : false;
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  padding: 10px 0;
  .logo {
    width: 60px;
    display: inline-block;
  }
  .main-btn {
    display: flex;
    align-items: center;
  }
  .icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    fill: #fff;
  }
}
.hero-wrapper {
  padding: 60px 0;
  .title {
    font-size: 40px;
    font-weight: 700;
    color: #181818;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 50px;
  }
  .widget {
    display: block;
    border-radius: 6px;
    padding: 45px 25px;
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
      box-shadow: 0 20px 30px -11px rgba(0, 0, 0, 0.2);
      transform: translate(0px, -5px);
    }
    .icon {
      width: 70px;
      height: 70px;
      margin: auto;
      fill: #fff;
      &.l-height {
        width: 50px;
        height: 50px;
        margin: 10px auto;
      }
    }
    .widget-title {
      text-align: center;
      font-size: 21px;
      font-weight: 400;
      color: #fff;
    }
    &.bg-red {
      background: #f0664e;
    }
    &.bg-blue {
      background: #65b0ba;
    }
    &.bg-orange {
      background: #ffcb62;
    }
  }
}
@media (min-width: 992px) {
  .hero-wrapper {
    .title {
      font-size: 80px;
    }
  }
}
</style>
